import { Route, RouteProps, useLocation } from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '../PrivateRoute/index.scss'
import Layout from '../../layout'
import { CheckShowPermissionsRouter } from './PrivateRoute.utils'
import useUser from 'features/shared/hooks/useUser'

const Wrapper: React.FC = ({ children }) => <>{children}</>
const AuthWrapper = withAuthenticator(Wrapper as any) as any;

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { userCurrent } = useUser()
  const location = useLocation();

  return (
    <Route {...rest}>
      <CheckShowPermissionsRouter locationPathName={location.pathname} roleSlug={userCurrent?.roleSlug || ""}>
        <AuthWrapper>
          <Layout loader={false}> {children} </Layout>
        </AuthWrapper>
      </CheckShowPermissionsRouter>
    </Route>
  )
}

export default PrivateRoute
