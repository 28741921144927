import Breadcrumbs from "components/Breadcrumbs";
import { BREADCRUMB_ORIGIN, getBreadcrumbDetails } from "features/shared/utils/getBreadcrumbDetails";
import { Map } from "../components/map";
import { useEffect, useState } from "react";
import { loadVisitDirections } from "../components/map.utils";
import { IGeoVisit, IRouteData, TMapsData } from "../types";
import { useSearchPatientsAttentions } from "features/Patients/hooks/usePatient";
import useUser from "features/shared/hooks/useUser";
import SelectStartingPoint from "../components/selectStartingPoint";
import { StartingPointOptionsDefault } from "../components/map.const";
import InputDatePicker from 'components/InputDatePicker';
import moment from "moment";

const {
  testUserLocation,
  testOrigin,
} = {
  testUserLocation: {
    lat: -12.08815149321723,
    lng: -77.06451280780455,
    label: "Current User Location"
  },
  testOrigin: { lat: -12.090615791485307, lng: -77.00761853573417, label: "Hospital" },
}

export function MapBaseView() {
  //This is the data that marks the required directions
  const [directionsData, setDirectionsData] = useState<IRouteData | undefined>(undefined);
  const [error, setError] = useState<any>(null)
  const [date, setDate] = useState<string>();
  //This is the data that marks the required stops / points
  const [visits, setVisits] = useState<IGeoVisit[]>([]);
  const { userCurrent } = useUser();

  const [filterData, setFilterData] = useState<{
    startingPoint?: IGeoVisit,
    dateTime?: string
  } | null>(null);

  const origin = filterData?.startingPoint || testOrigin;
  const [mapData, setMapData] = useState<TMapsData>(null as any);

  const { resultingElements } = getBreadcrumbDetails({
    location: location as any,
    from: BREADCRUMB_ORIGIN.MAP,
    additionalData: {}
  })

  const {
    mutateAsync: mutateAsyncSearchPatientAttentions,
  } = useSearchPatientsAttentions();

  const currentUserLocation = !filterData?.startingPoint ? testUserLocation : undefined;

  async function fetchAttentions() {
    try {
      const { data: { data } } = await mutateAsyncSearchPatientAttentions({
        inputChange$: null as any,
        page: 1,
      });

      const newGeoVisits = data.filter(element => !element.epicrisisId).map((element) => {
        return {
          lat: element.addressLat || 0,
          lng: element.addressLng || 0,
          label: `${element.patientId || ""}`,
          attention: element
        }
      })
      setVisits(newGeoVisits);
    } catch (e) {
      setError(e);
    }
  }

  useEffect(() => {
    if (userCurrent) {
      fetchAttentions();
    }
  }, [userCurrent])

  const enabled = origin && visits && mapData?.maps?.LatLng && !error && filterData?.startingPoint;
  const hasSelectedStartingPoint = !!filterData?.startingPoint;

  function calculateRoute() {
    if (enabled) {
      loadVisitDirections({
        origin,
        visits,
        currentUserLocation,
        mapData
      }).then((response) => {
        setDirectionsData(response);
      }).catch(error => {
        console.error("Error loading visit directions:", error);
        setError(error);
      });
    }
  }

  return (
    <div>
      <Breadcrumbs
        name={''}
        currentPathName={""}
        elements={resultingElements}
      />
      <div className="pt-8">
        <div className="bg-white rounded-xl shadow-md p-4 flex-row justify-start w-full">
          <div className="w-full h-[104px]">
            <div className="px-4 pb-[24px] flex flex-col justify-between lg:flex-row xl:flex-row">
              <div className="flex flex-row justify-left items-center w-full">
                <p className="mr-4">
                  <span className="font-medium text-text-primary" data-testid="pacientes-label">Visitas</span>
                  <span className="ml-2 mr-2">|</span>
                  <span data-testid="pacientes-number-label">{visits?.length || 0}</span>
                </p>
                <div className={`w-[175px] h-[48px] rounded-[20px] flex justify-center items-center text-white
                  font-poppins text-base font-semibold leading-6 tracking-wide text-center
                  ${enabled ? 'cursor-pointer bg-[#00B1CC]' : 'bg-[#F6F6F6]'}`} onClick={calculateRoute}>
                  Crear Ruta
                </div>
              </div>
              <div className="flex flex-col sm:flex-row sm:grid sm:grid-cols-2">
                <div style={{
                  marginRight: "200px"
                }}>
                  <SelectStartingPoint classNameWrapper="w-[200px]" onChangeTop={(value) => {
                    const selected = StartingPointOptionsDefault.find(element => {
                      return element.value === value
                    })
                    setFilterData({
                      ...filterData,
                      startingPoint: selected
                    })
                  }} />
                </div>
                <InputDatePicker
                  label="Fecha inicio"
                  value={date}
                  selected={new Date()}
                  onChange={(date: any) => {
                    setDate(moment(date).format("DD/MM/YYYY"));
                  }}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale="es"
                  dateFormat="dd/MM/yyyy"
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-[70vh] border border-[#585A5B] rounded-lg">
            {
              !error && <Map
                origin={origin}
                visits={hasSelectedStartingPoint ? visits : []}
                directions={directionsData}
                mapData={mapData}
                setMapData={setMapData}
              />
            }
            {
              error && <div>Sucedió un error al cargar el mapa.</div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}