import React, { useState, useEffect } from 'react'
import Moment from "moment";
import CalendarIcon from 'assets/icons/icon_calendar.svg';
import IconDelete from 'assets/icons/icon_delete_a.svg';

import InputDatePicker from 'components/InputDatePicker';
import LabelForm from 'components/LabelForm';
import Schedule from './schedule';
import Calendar from './Calendar';

const CardDate = (props: any) => {
  const [currentCalendarType, setCurrentCalendarType] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);
  const [firtsOpen, setFirtsOpen] = useState(false);

  const [multiDate, setMultiDate] = useState<any>([]);
  const [multiDateFormat, setMultiDateFormat] = useState<any>([]);
  const [startDateRange, setStartDateRange] = useState(new Date());
  const [endDateRange, setEndDateRange] = useState(new Date());
  const onChangeRange = (dates: any) => {
    const [start, end] = dates;
    setStartDateRange(start);
    setEndDateRange(end);
  };



  const handleClick = () => {

    setIsOpen(!isOpen);
    setFirtsOpen(true)
  };


  useEffect(() => {
    setIsOpen(false);

    if (props.initialData) {
      const ordenDay = (props.initialData.days).sort((a: number, b: number) => { return a - b });
      const startDateRange = new Date(`${props.initialData.month}-${ordenDay[0]}-${props.initialData.year}`);
      const endDateRange = new Date(`${props.initialData.month}-${ordenDay[(ordenDay).length - 1]}-${props.initialData.year}`);
      let arrayDateMultiFormat: any = [];

      ordenDay.forEach((day: any) => {
        let dateMulti = new Date(`${props.initialData.month}-${day}-${props.initialData.year}`);
        arrayDateMultiFormat.push(Moment(dateMulti).format('DD/MM/YYYY'));
      });

      if ((ordenDay[0] + (ordenDay).length - 1) !== (ordenDay[ordenDay.length - 1])) {
        setCurrentCalendarType(2)
        setMultiDateFormat(arrayDateMultiFormat);
      } else {
        setStartDateRange(startDateRange);

        setEndDateRange(endDateRange);

      }

    }
  }, []);

  return (
    <React.Fragment>
      <div className=" w-full relative  bg-white rounded-xl border-solid border-[1px] border-grey-400  px-4 py-[24px]">
        <LabelForm
          label={props.label} className='font-medium mb-[16px]'>
          <img
            onClick={() => { props.setData({ currentCalendarType, startDateRange, endDateRange, multiDate }); props.trashClick() }}
            className='absolute w-[25px] h-[25px] top-[20px] right-[20px] cursor-pointer'
            src={IconDelete}
            alt='eliminar ' /></LabelForm>
        {currentCalendarType === 1 ?
          <Schedule className={'cursor-pointer'} title={'Hora'} description={`${Moment(startDateRange).format('DD/MM/YYYY')} - ${Moment(endDateRange).format('DD/MM/YYYY')} `} icon={CalendarIcon} onClickIcon={handleClick} />
          :
          <Schedule className={'cursor-pointer'} title={'Hora'} description={(multiDateFormat).toString()} icon={CalendarIcon} onClickIcon={handleClick} />
        }



        <button
          disabled={!firtsOpen || isOpen || (currentCalendarType === 1 ? (startDateRange === null || endDateRange === null) : multiDate.length === 0)}
          type="button" onClick={() => {
            props.setData({ currentCalendarType, startDateRange, endDateRange, multiDate });
            props.onSubmit()

            setIsOpen(!isOpen);

          }} className=" mt-[16px]
        min-h-[48px] rounded-[20px] text-button 
        
          px-[24px] bg-primary-dark text-white border-solid border-[1px] border-primary-dark 
          disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-50
         
        w-full  
         
      ">
          {props.initialData ? 'Actualizar' : 'Guardar'}
        </button>

      </div>
      {isOpen &&
        <div className={`relative react-datepicke-content rounded-xl border-solid border-[1px] border-grey-400 bg-white   `}>

          <div className="bg-white  pb-[0]">
            <div className="text-subtitle1 font-medium mb-[16px] 	">Que días se asignarán</div>
            <div className="flex w-full justify-between	mb-[8px]">
              <div onClick={() => { setCurrentCalendarType(1) }} className={`${currentCalendarType === 1 ? 'bg-primary-main text-white' : 'border-primary-main text-primary-main border-solid border-[1px]'} pointer items-center	 min-h-[36px] rounded-[8px]	rounded-r-[0px]	 flex justify-center	 w-full  text-caption`}>Por rango</div>
              <div onClick={() => { setCurrentCalendarType(2) }} className={` ${currentCalendarType === 2 ? 'bg-primary-main text-white' : 'border-primary-main text-primary-main border-solid border-[1px]'} pointer min-h-[36px]  items-center  rounded-[8px]	rounded-l-[0px]	   flex justify-center	 w-full  text-caption`}>Por día</div>
            </div>


          </div>


          {currentCalendarType === 1 ?

            <InputDatePicker
              id={props.id + 'ppp'}
              selected={startDateRange}
              onChange={onChangeRange}
              startDate={startDateRange}
              endDate={endDateRange}
              selectsRange
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale="es"
              dateFormat="dd/MM/yyyy "
              minDate={new Date()}
              maxDate={new Date(parseInt(Moment().format('YYYY')) + 3, 12, 31)}
              inline={true}
            />

            :

            <Calendar
              id={props.id + 'rrr'}
              initialData={props.initialData}
              className={'calendar-content'}
              setData={(data: any) => {
                let dataDate = data.map((element: any) => {
                  return (element.date)
                });
                let dataDateFormart = data.map((element: any) => {
                  return (element.format)
                });
                setMultiDate(dataDate)
                props.setData({ currentCalendarType, startDateRange, endDateRange, multiDate: dataDate });
                setMultiDateFormat(dataDateFormart)
              }}

            />
          }

          <div className=" ">
            <button
              onClick={() => { setIsOpen(false); props.setData({ currentCalendarType, startDateRange, endDateRange, multiDate }) }}
              type="button" className=" 
              mt-[16px] min-h-[48px] rounded-[20px] text-button px-[24px] bg-primary-dark text-white 
              border-solid border-[1px] border-primary-dark 
              disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-50
              w-full max-w-[343px]
              ">
              Aplicar
            </button>
          </div>

        </div>}
    </React.Fragment>
  )
}

export default CardDate
