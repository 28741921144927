import { t } from "i18next"
import HomeIcon from 'assets/icons/icon_home.svg';
import HealthIcon from 'assets/icons/icon_health.svg';
import UsersIcon from 'assets/icons/icon_users.svg';
import ClinicTableIcon from 'assets/icons/icon_clinic_table.svg';
import BotIcon from 'assets/icons/icon_bot.svg';
import IconUser from 'assets/icons/icon_user.svg';
import FileIcon from 'assets/icons/icon_file.svg';
import ErrorTriangleIcon from 'assets/icons/icon_error_triangle.svg';
import PressureIcon from 'assets/icons/icon_pressure_black.svg';
import NotificationsIcon from 'assets/icons/icon_notification.svg'
import FolderIcon from 'assets/icons/icon_folder.svg'
import MapPin from 'assets/icons/map_pin.svg'

import { PERMISSIONS, ROLE } from "global/constants/roles";
import { REFERENCE_IDS, URL_PARAMS_NAME } from "features/Patient/views/index.utils";

enum TypeMenu {
  section = 'section',
  group = 'group',
  menu = 'menu',
};

export const routes = {
  // home hospital patient (client) views
  patientDashboard: {
    name: 'Plan de trabajo',
    url: '/patient-dashboard',
    permissionSlug: [PERMISSIONS.MY_PLAN],
    allowedRolesAccess: [ROLE.PATIENT],
    icon: IconUser,
    type: TypeMenu.menu,
  },
  // home hospital users views
  monitoring: {
    name: 'Monitoreo',
    url: '/monitoring',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: PressureIcon,
    type: TypeMenu.menu,
  },
  /**
   * @deprecated Use monitoringForPatient instead
   */
  monitoringPatient: {
    name: 'Monitoreo de paciente',
    url: '/patient/:patientId',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  monitoringForPatient: {
    name: 'Monitoreo de paciente',
    url: '/monitoring/patient/:patientId',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  monitoringForPatientResponsible: { // TODO: Replace occurrences of this route with monitoringForPatientResponsible
    name: 'Monitoreo de paciente',
    url: '/monitoring/patient/:patientId/responsible',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  monitoringForPatientService: { // TODO: Replace occurrences of this route with monitoringForPatientService
    name: 'Monitoreo de paciente',
    url: '/monitoring/patient/:patientId/service',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  monitoringForPatientEconomic: { // TODO: Replace occurrences of this route with monitoringForPatientEconomic
    name: 'Monitoreo de paciente',
    url: '/monitoring/patient/:patientId/economic',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  clinicHistory: {
    name: 'Historial',
    url: '/history',
    permissionSlug: [PERMISSIONS.CLINIC_HISTORY],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: FileIcon,
    type: TypeMenu.menu,
  },
  clinicHistoryPatientV2: {
    name: 'Paciente',
    url: '/history/patient/:documentPatient',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  clinicHistoryPatientAddAttention: {
    name: 'Paciente',
    url: `/history/patient/:documentPatient?${URL_PARAMS_NAME.TAB}=${REFERENCE_IDS.SERVICE_DATA}`,
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  clinicHistorySpecificAttentionsPatient: {
    name: 'Lista de Atenciones',
    url: '/history/patient/:documentPatient/attentions',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  clinicHistorySpecificAttentionForPatient: {
    name: 'Detalles de la Atención',
    url: '/history/patient/:documentPatient/attentions/:attentionId',
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },

  clinicHistoryForPatient: {
    name: 'Atención Paciente',
    url: '/history/patient/:patientId',
    permissionSlug: [PERMISSIONS.CLINIC_HISTORY],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },

  attentions: {
    name: 'Atenciones',
    url: '/attentions',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  attentionsActionEditPatient: {
    name: 'Atenciones - Paciente',
    url: '/attentions/patient/:patientId',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  attentionsPatientHistory: { //TODO: Replace occurrences of this route with attentionsPatientHistory
    name: 'Atenciones - Historial de la Atención',
    url: '/attentions/patient/:patientId/history',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  patientWorkplan: {
    name: 'Atenciones - Paciente',
    url: '/attentions/patient/:patientId/workplan',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  patientEpicrisis: { //TODO: Replace occurrences of this route with patientEpicrisis
    name: 'Atenciones - Paciente',
    url: '/attentions/patient/:patientId/epicrisis',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    //allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  patientDailyEvolution: {
    name: 'Atenciones - Evolución Diaria',
    url: '/attentions/patient/:patientId/daily-evolution',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  attentionsActionPatientAttentionsHistory: {
    name: 'Atenciones - Historial de Paciente',
    url: '/attentions/patients/:documentPatient/attentions',
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  attentionsActionPatientAttentionsHistorySpecificAttention: {
    name: 'Atenciones - Detalles de Atenciones',
    url: '/attentions/patients/:documentPatient/attentions/:attentionId',
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  home: {
    name: t('home'),
    url: '/',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  patients: {
    name: "Activos",
    url: '/patients',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: IconUser,
    type: TypeMenu.menu,
  },
  editPatient: {
    name: 'Paciente',
    url: '/patients/edit-patient/:documentPatient',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  /**
   * @deprecated Use editPatient instead.
   */
  patient: {
    name: 'Paciente',
    url: '/edit-patient',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  /**
   * @deprecated Use patientHistoryElement instead.
   */
  patientHistory: {
    name: 'Paciente',
    url: '/patient/history',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  patientHistoryElement: {
    name: 'Paciente',
    url: '/patients/patient/history/:documentPatient',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  patientHistoryElementAttentionDetails: {
    name: 'Paciente',
    url: '/patients/patient/history/:documentPatient/attention/:attentionId',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  newPatient:
  {
    name: 'Nuevo Paciente',
    url: '/patients/new',
    permissionSlug: [PERMISSIONS.PATIENT_ONBOARDING],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },

  callCenter: {
    name: 'Central de atención',
    url: '/call-center',
    permissionSlug: [PERMISSIONS.COMMAND_CENTER],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },

  pharmacyPatient: {
    name: 'Por usuario',
    url: '/pharmacy/patient',
    permissionSlug: [PERMISSIONS.PHARMACY_PER_USER],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: IconUser,
    type: TypeMenu.menu,
  },

  pharmacyProduct: {
    name: 'Por producto',
    url: '/pharmacy/product',
    permissionSlug: [PERMISSIONS.PHARMACY_PER_PRODUCT],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: HealthIcon,
    type: TypeMenu.menu,
  },

  devices: {
    name: 'Dispositivos reportados',
    url: '/devices',
    permissionSlug: [PERMISSIONS.DEVICE_REPORT],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: ErrorTriangleIcon,
    type: TypeMenu.menu,
  },

  userManagementNewRol: {
    name: 'Roles',
    url: '/roles',
    permissionSlug: [PERMISSIONS.ROLE],
    allowedRolesAccess: [ROLE.SUPER_ADMIN],
    icon: BotIcon,
    type: TypeMenu.menu,
  },

  userManagement: {
    name: 'Usuarios',
    url: '/user-management',
    permissionSlug: [
      PERMISSIONS.MANAGEMENT_BACKOFFICE,
      PERMISSIONS.MANAGEMENT_SPECIALIST,
      PERMISSIONS.MANAGEMENT_ADM_SERVICE
    ],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.SUPER_ADMIN, ROLE.COUNTRY_ADMIN],
    icon: UsersIcon,
    type: TypeMenu.menu,
  },
  userManagementCalendar: {
    name: 'Disponibilidad',
    url: '/user-management/availability/:userId',
    permissionSlug: [
      PERMISSIONS.MANAGEMENT_BACKOFFICE,
      PERMISSIONS.MANAGEMENT_SPECIALIST,
      PERMISSIONS.MANAGEMENT_ADM_SERVICE
    ],
    icon: UsersIcon,
    type: TypeMenu.menu,
  },
  userManagementNewUser: {
    name: 'Gestión de usuarios nuevo usuario',
    url: '/user-management/new-user',
    permissionSlug: [
      PERMISSIONS.MANAGEMENT_BACKOFFICE,
      PERMISSIONS.MANAGEMENT_SPECIALIST,
      PERMISSIONS.MANAGEMENT_ADM_SERVICE
    ],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  userManagementEditUser: {
    name: 'Gestión de usuarios deitar usuario',
    url: '/user-management/user/:userId',
    permissionSlug: [
      PERMISSIONS.MANAGEMENT_BACKOFFICE,
      PERMISSIONS.MANAGEMENT_SPECIALIST,
      PERMISSIONS.MANAGEMENT_ADM_SERVICE
    ],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.SUPER_ADMIN, ROLE.COUNTRY_ADMIN],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  userManagementDevicesAndMedications: {
    name: 'Dispositivos médicos',
    url: '/devices-and-medications',
    permissionSlug: [
      PERMISSIONS.DEVICE_SUPPLIES
    ],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN],
    icon: PressureIcon,
    type: TypeMenu.menu,
  },
  notifications: {
    name: 'Notificaciones',
    url: '/notifications',
    permissionSlug: [PERMISSIONS.PATIENT_MONITORING, PERMISSIONS.MY_PLAN],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL, ROLE.PATIENT],
    icon: NotificationsIcon,
    type: TypeMenu.menu,
  },
  readNotifications: {
    name: 'Notificaciones atendidas',
    url: '/notifications/read',
    permissionSlug: [],
    icon: HomeIcon,
    type: TypeMenu.menu,
  },
  programming: {
    name: 'Programación',
    url: '/programming',
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    permissionSlug: [],
    icon: FolderIcon,
    type: TypeMenu.menu,
  },
  map: {
    name: 'Mapa',
    url: '/map',
    permissionSlug: [],
    allowedRolesAccess: [ROLE.SERVICE_ADMIN, ROLE.HEALTH_PROFESSIONAL],
    icon: MapPin,
    type: TypeMenu.menu,
  },
  clinicNetwork: {
    name: 'Red de clínicas',
    url: '/clinic-networks',
    permissionSlug: [PERMISSIONS.CLINIC_NETWORKS],
    allowedRolesAccess: [ROLE.SUPER_ADMIN],
    type: TypeMenu.menu,
  },
  clinics: {
    name: 'Clínicas',
    url: '/clinics/:clinicNetworkId/clinics',
    permissionSlug: [PERMISSIONS.CLINICS],
    allowedRolesAccess: [ROLE.COUNTRY_ADMIN],
    type: TypeMenu.menu,
  },
};

const superAdmin = [
  {
    name: 'Gestión',
    type: TypeMenu.section,
    list: [
      {
        name: 'Centros',
        type: TypeMenu.group,
        icon: ClinicTableIcon,
        list: [
          routes.clinicNetwork,
        ]
      }
    ]
  },
  {
    ...routes.userManagementNewRol,
    principal: true
  },
  {
    ...routes.userManagement,
    principal: true

  }
];

const countryAdmin = [
  {
    name: 'Gestión',
    type: TypeMenu.section,
    list: [
      {
        name: 'Centros',
        type: TypeMenu.group,
        icon: ClinicTableIcon,
        list: [
          routes.clinics,
        ]
      }
    ]
  },
  {
    ...routes.userManagement,
    principal: true
  }
];

const serviceAdmin = [
  {
    name: 'Pacientes',
    type: TypeMenu.section,
    list: [
      {
        ...routes.patients,
        principal: true
      },
      {
        ...routes.clinicHistory,
        principal: true
      },
      {
        ...routes.attentions,
        principal: true
      },
      {
        ...routes.monitoring,
        principal: true
      },
    ]
  },
  {
    name: "Farmacia",
    type: TypeMenu.section,
    list: [{
      ...routes.pharmacyProduct,
      principal: true
    }, {
      ...routes.pharmacyPatient,
      principal: true
    }]
  },
  {
    name: 'Gestión',
    type: TypeMenu.section,
    list: [
      {
        ...routes.userManagementDevicesAndMedications,
        principal: true
      },
      {
        ...routes.devices,
        principal: true
      },
      {
        ...routes.userManagement,
        principal: true
      }, {
        ...routes.notifications,
        principal: true
      }
    ]
  },
  {
    name: "Zona de cobertura",
    type: TypeMenu.section,
    list: [
      {
        ...routes.programming,
        principal: true
      },
      {
        ...routes.map,
        principal: true
      },
    ]
  }
];

const treatingMedical = [
  {
    ...routes.monitoring,
    principal: true
  }
];

const professionalHealth = [
  {
    name: "Pacientes",
    type: TypeMenu.section,
    list: [
      {
        ...routes.attentions,
        principal: true
      },
      {
        ...routes.monitoring,
        principal: true
      },
      {
        ...routes.notifications,
        principal: true
      },
    ]
  },
  {
    name: "Zona de cobertura",
    type: TypeMenu.section,
    list: [
      {
        ...routes.programming,
        principal: true
      },
      {
        ...routes.map,
        principal: true
      },
    ]
  }
];

const patient = [
  {
    ...routes.patientDashboard,
    principal: true
  }, {
    ...routes.notifications,
    principal: true
  }
];


export const roleMenuList = {
  superAdmin,
  serviceAdmin,
  treatingMedical,
  countryAdmin,
  patient,
  professionalHealth
};

