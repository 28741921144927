import { NotificationType } from "features/Notifications/services";
import { EventType } from "global/constants/eventType";
import { ROLE } from "global/constants/roles";
import { NotificationCardProps } from "./types";
import { getAtHeaderDate, getHeaderDate } from "../utils";
import { variants } from "../NotificationsCounter/NotificationsCounterConst";

export const notificationVariants: Record<NotificationType, any> = {
  call: {
    iconName: variants.call.active.iconName,
    labels: { action: 'Llamar', readByPreffix: 'Atendió la llamada' },
    bg: 'bg-[#48B16E]',
    color: 'text-[#48B16E]',
    titleType: 'Llamadas',
    iconAlert: variants.call.active.iconName,
  },
  medication: {
    iconName: variants.medication.active.iconName,
    labels: { action: 'Responder', readByPreffix: 'Atendido por' },
    bg: 'bg-[#00B1CC]',
    color: 'text-[#00B1CC]',
    titleType: 'Medicamentos',
    iconAlert: variants.medication.active.iconName,
  },
  report: {
    iconName: variants.report.active.iconName,
    labels: { action: 'Ver detalle', readByPreffix: 'Revisado por' },
    bg: 'bg-[#407FE7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: variants.report.active.iconName,
  },
  prescription: {
    iconName: variants.prescription.active.iconName,
    labels: { action: 'Ver', readByPreffix: 'Atendido por' },
    bg: 'bg-[#00B1CC]',
    color: 'text-[#00B1CC]',
    titleType: 'Medicamentos',
    iconAlert: variants.prescription.active.iconName,
  },
  recommendation: {
    iconName: variants.recommendation.active.iconName,
    labels: { action: 'Ver', readByPreffix: 'Revisado por' },
    bg: 'bg-[#407FE7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: variants.recommendation.active.iconName,
  },
  annotations: {
    iconName: variants.recommendation.active.iconName,
    labels: { action: 'Ver', readByPreffix: 'Revisado por' },
    bg: 'bg-[#407FE7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: variants.recommendation.active.iconName,
  },
  consultation: {
    iconName: variants.consultation.active.iconName,
    labels: { action: 'Asignar', readByPreffix: 'Revisado por' },
    bg: variants.consultation.active.bg,
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: variants.consultation.active.iconName,
  },
  queryWorkplan: {
    iconName: variants.consultation.active.iconName,
    labels: { action: 'Ver Detalle', readByPreffix: 'Revisado por' },
    bg: variants.consultation.active.bg,
    color: 'text-[#00B1CC]',
    titleType: 'Dispositivos',
    iconAlert: variants.consultation.active.iconName,
  },
  attended: {
    iconName: variants.consultation.active.iconName,
    labels: { action: 'Ver Detalle', readByPreffix: 'Revisado por' },
    bg: variants.consultation.active.bg,
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: variants.consultation.active.iconName,
  },
  ['patient-events']: {
    iconName: variants.consultation.active.iconName,
    labels: { action: 'Ver Detalle', readByPreffix: 'Revisado por' },
    bg: variants.consultation.active.bg,
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: variants.consultation.active.iconName,
  }
}

const handleAction = ({
  title
}: {
  title: string,
}) => {

  return () => {

  }
}

export const handleNotification = ({
  element,
  title,
  actionGenerated
}: {
  element: string,
  title: string,
  actionGenerated: string | null
}) => {
  // Acá usas template literals para poner tu estilo junto con notificationVariants[variant].color.
  const color = (notificationVariants as any)[element].color;
  return {
    actionClass: `font-poppins text-[12px] font-medium leading-4 tracking-[-0.2px] text-left decoration-skip-ink-none ${color}`, // Le podrías quizás poner otro nombre a actionClass.
    title,
    actionGenerated
  };
}


export const returnedCallAction = (notification: NotificationCardProps['notification']) => {
  if (!!notification.readAt) {
    const actionPerformed = "Se devolvió la llamada ";
    const dateFormattedDisplay = getAtHeaderDate(notification.readAt);
    return `${actionPerformed} ${dateFormattedDisplay}`;
  } else {
    return null;
  }
}

export const revisitedAtAction = (notification: NotificationCardProps['notification']) => {
  if (!!notification.readAt) {
    const actionPerformed = "Revisado el ";
    const dateFormattedDisplay = getAtHeaderDate(notification.readAt);
    return `${actionPerformed} ${dateFormattedDisplay}`;
  } else {
    return null;
  }
}

export const seenAtAction = (notification: NotificationCardProps['notification']) => {
  if (!!notification.readAt) {
    const actionPerformed = "Visto ";
    const dateFormattedDisplay = getAtHeaderDate(notification.readAt);
    return `${actionPerformed} ${dateFormattedDisplay}`;
  } else {
    return null;
  }
}

export const assignedAtAction = (notification: NotificationCardProps['notification']) => {
  if (!!notification.readAt) {
    const actionPerformed = "Asignado ";
    const dateFormattedDisplay = getAtHeaderDate(notification.readAt);
    return `${actionPerformed} ${dateFormattedDisplay}`;
  } else {
    return null;
  }
}

export const generatedAt = (notification: NotificationCardProps['notification']) => {
  if (!!notification.readAt) {
    const actionPerformed = "Generado ";
    const dateFormattedDisplay = getAtHeaderDate(notification.readAt);
    return `${actionPerformed} ${dateFormattedDisplay}`;
  } else {
    return null;
  }
}


export const elementsByRole = {
  [ROLE.PATIENT]: {
    [EventType.NOTIFICATION_TYPE_EMERGENCY_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tienes una llamada perdida de emergencia de tu médico. Comunícate a la brevedad.",
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_DOCTOR_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tienes una llamada perdida de tu médico. Comunícate a la brevedad.",
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_SPECIALIST_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tienes una llamada perdida de tu médico. Comunícate a la brevedad.",
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_ADM_SERVICE_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tienes una llamada perdida de tu administrador de servicio. Comunícate a la brevedad.",
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_NOTE_DR_DAILY_EVOL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'annotations',
      title: "El médico ha dejado anotaciones en evolución diaria.",
      actionGenerated: revisitedAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_NOTE_PATIENT]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'annotations',
      title: "El médico ha dejado anotaciones en evolución diaria.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_PRESCRIPTION_CREATED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "Se actualizaron los medicamentos en el plan de trabajo.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_PRESCRIPTION_UPDATED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "Se actualizaron los medicamentos en el plan de trabajo.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_PRESCRIPTION_DELETED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "Se actualizaron los medicamentos en el plan de trabajo.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_PRESCRIPTION_DELETED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "Se actualizaron los medicamentos en el plan de trabajo.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_RECOMMENDATION]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "Se añadió una recomendación a su plan de trabajo.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_MEDICAL_PERSONAL_ASSIGNED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "Se le ha asignado un nuevo personal médico.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_DEVICE_ASSIGNED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "Se le ha asignado un nuevo dispositivo médico.",
      actionGenerated: seenAtAction(notification)
    }),
  },
  [ROLE.HEALTH_PROFESSIONAL]: {
    [EventType.NOTIFICATION_TYPE_EMERGENCY_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tienes una llamada perdida de tu paciente. Comunícate a la brevedad.",
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_DOCTOR_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tienes una llamada perdida de tu paciente. Comunícate a la brevedad.",
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_PATIENT_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tienes una llamada perdida de tu paciente. Comunícate a la brevedad.",
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_ADM_SERVICE_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tienes una llamada perdida del administrador de servicio.",
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_REPORT_DEVICE]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'report',
      title: "Se le ha asignado un nuevo dispositivo a tu paciente.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_PATIENT_RELEASED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "El paciente ha sido dado de alta exitosamente.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_DEVICE_ASSIGNED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: "Se la ha asignado un dispositivo a su paciente.",
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_NOTE_PATIENT]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'annotations',
      title: "El paciente ha dejado anotaciones en evolución diaria.",
      actionGenerated: seenAtAction(notification)
    }),
  },
  [ROLE.SERVICE_ADMIN]: {
    [EventType.NOTIFICATION_TYPE_NOT_MEDICATION]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: `El paciente no ha tomado el medicamento _MEDICINE_NAME_`.replace("_MEDICINE_NAME_", (notification.data?.body as any)?.supplyName || ""),
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_EMERGENCY_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: 'El paciente ha reportado una llamada de emergencia. Comunícate rápidamente.',
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_DOCTOR_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: 'El paciente ha reportado una llamada de emergencia de tu doctor. Comunícate rápidamente.',
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_ADM_SERVICE_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: 'Tienes una llamada perdida del administrador de servicio.',
      actionGenerated: returnedCallAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_PATIENT_RELEASED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: 'El paciente ha sido dado de alta exitosamente.',
      actionGenerated: seenAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_SPECIALISTY_REQUESTS_CREATED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: `Se ha solicitado una interconsulta para _GDR_ paciente _FN_ _LN_`
        .replace("_GDR_", notification.patientGender === 'F' ? 'la' : 'el')
        .replace("_FN_", notification.patientFirstName || "")
        .replace("_LN_", notification.patientLastName || ""),
      actionGenerated: assignedAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_CONSULTATION_SPECIALISTY_REQUESTS]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'queryWorkplan',
      title: `Se ha solicitado una interconsulta para _GDR_ paciente _FN_ _LN_`
        .replace("_GDR_", notification.patientGender === 'F' ? 'la' : 'el')
        .replace("_FN_", notification.patientFirstName || "")
        .replace("_LN_", notification.patientLastName || ""),
      actionGenerated: assignedAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_REPORT_DEVICE]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'report',
      title: "Se ha reportado una incidencia con uno de los dispositivos médicos.",
      actionGenerated: revisitedAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_SUCCESSFUL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'report',
      title: notification.data.title,
      actionGenerated: assignedAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_FAILED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'report',
      title: notification.data.title,
      actionGenerated: generatedAt(notification)//assignedAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_DEVICE_ASSIGNED]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'report',
      title: "Se ha asignado un dispositivo médico a un paciente.",
      actionGenerated: generatedAt(notification)//assignedAtAction(notification)
    }),
    [EventType.NOTIFICATION_TYPE_SPECIALIST_CALL]: (notification: NotificationCardProps['notification']) => handleNotification({
      element: 'call',
      title: "Tiene una llamada perdida de un especialista.",
      actionGenerated: returnedCallAction(notification)//assignedAtAction(notification)
    }),
  }
}