import { Dispatch, SetStateAction, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { IGeoPoint, IGeoVisit, IRouteData, TMapsData } from "../types";
import "./map.css";
import { getDefaultCenter, initializeDirectionMarkers } from "./map.utils";
import constants from 'configs/constants'
import { StartingPointOptionsDefault } from "./map.const";

const apiKey = constants.REACT_APP_GOOGLE; // Replace with your Google Maps API key

export const Map = ({
  currentUserLocation,
  visits,
  origin,
  directions,
  mapData,
  setMapData
}: {
  currentUserLocation?: IGeoVisit,
  visits: IGeoVisit[]
  origin: IGeoVisit,
  directions?: IRouteData,
  mapData: TMapsData,
  setMapData: Dispatch<SetStateAction<TMapsData>>
}) => {

  const defaultCenter: IGeoPoint = getDefaultCenter({
    currentUserLocation,
    visits
  }) || StartingPointOptionsDefault[0];

  const [error, setError] = useState<any>();

  const [mapPlotData, setMapPlotData] = useState<any>();

  useEffect(() => {
    //We reset all plotting data.
    mapPlotData?.directionsRendererInstance?.setMap(null);
    mapPlotData?.markers?.forEach((element: any) => {
      element.marker.setMap(null);
    })
    try {
      const newData = initializeDirectionMarkers({
        visits,
        origin,
        mapData,
        directions,
        currentUserLocation
      });
      setMapPlotData({
        markers: newData.newMarkers,
        directionsRendererInstance: newData.directionsRendererInstance
      })
    } catch (e) {
      console.error("Error executing initializeDirectionsMarker: ", {
        e
      });
      setError(e);
    }
  }, [visits, mapData?.maps, directions, currentUserLocation])

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {
        defaultCenter && mapPlotData && !error && <GoogleMapReact
          bootstrapURLKeys={{
            key: apiKey, language: 'es',
            libraries: [
              'core',
              'maps',
              'places',
              'geocoding',
              'routes',
              'marker',
              'geometry',
            ]
          }}
          defaultCenter={defaultCenter}
          defaultZoom={13}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            setMapData({
              map,
              maps
            })
          }}
          onClick={(event) => { console.log(event) }}
        />
      }
      {
        error && <div>Ocurrió un error al cargar el mapa</div>
      }
    </div>
  );
};