import React, { useCallback, useEffect, useState } from 'react'
import LabelForm from 'components/LabelForm'
import {
  useMeetingManager,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import Chime from './Chime'
import IconVideo from 'assets/icons/icon_video_line.svg'
import {
  useCreateMeetingChime,
  useGetMeetingUserJoined,
} from 'features/ClinicHistory/hooks/useMeetingChime'
import useUser from 'features/shared/hooks/useUser'

export interface MeetingVideoCallProps {
  planId?: any
  userProfile?: number
  userId?: number
  getIsClose?: Function
  initCall?: boolean
  showLabel?: boolean
  withBorder?: boolean
  onLeaveMeeting?: Function
}

export const MeetingVideoCall: React.FC<MeetingVideoCallProps> = ({
  planId,
  userProfile,
  userId,
  getIsClose,
  initCall,
  onLeaveMeeting,
  showLabel = true,
  withBorder = false,
}) => {
  const meetingManager = useMeetingManager()
  const [videoTiles, setVideoTiles] = useState(false)
  const { toggleMute } = useToggleLocalMute()
  const { mutate } = useCreateMeetingChime()
  const [mute, setMute] = useState(true)
  const { mutateAsync: getMeetingUserJoined } = useGetMeetingUserJoined()
  const { userCurrent } = useUser()

  useEffect(() => {
    meetingManager.getAttendee = async (
      chimeAttendeeId: string,
      externalUserId?: string
    ) => {
      const response = await getMeetingUserJoined(chimeAttendeeId)
      const user = response?.data

      return {
        name: user?.name,
        lastName: user?.lastname,
      }
    }
  }, [])

  const joinMeeting = useCallback(() => {
    mutate(
      {
        type: userProfile === 1 ? 'patient' : 'specialty',
        userId: userId,
        planId,
      },
      {
        onSuccess: async (data) => {
          const meetingSessionConfiguration = new MeetingSessionConfiguration(
            data?.JoinInfo?.Meeting,
            data?.JoinInfo?.Attendee
          )
          await meetingManager.join(meetingSessionConfiguration)
          await meetingManager.start()
          setVideoTiles(true)
        },
      }
    )
  }, [meetingManager, mutate, planId, userId, userProfile])

  useEffect(() => {
    if (initCall) {
      joinMeeting()
    }
    return () => {
      handleLeaveMeeting()
    }
  }, [initCall])

  const handleAudio = () => {
    toggleMute()
    setMute(!mute)
  }

  const handleLeaveMeeting = async () => {
    await meetingManager.leave()
    videoTiles && setVideoTiles && setVideoTiles(false)
    getIsClose && getIsClose(false)
    onLeaveMeeting && onLeaveMeeting()
  }

  return (
    <div
      className={
        withBorder
          ? 'bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-4 mb-4'
          : ''
      }
    >
      <div className="flex items-center justify-between">
        {showLabel ? (
          <LabelForm
            icon={IconVideo}
            label="Videollamada"
            className="mt-4 mb-2 row-span-1 col-span-full"
          />
        ) : null}
      </div>
      <div className="pt-9">
        <div
          className={`w-full
            max-w-[343px]
            xl:max-w-[450px]
            2xl:max-w-[621px]
            relative
            items-center
            rounded-[16px]
            justify-items-center
            m-auto p-0 ${!videoTiles && 'flex'
            }`}
        >
          {videoTiles && (
            <Chime
              videoTiles={videoTiles}
              handleAudio={handleAudio}
              handleLeaveMeeting={handleLeaveMeeting}
              mute={mute}
              alias={`${userCurrent?.name.charAt(
                0
              )}${userCurrent?.lastname.charAt(0)}`}
            />
          )}
        </div>
      </div>
    </div>
  )
}

