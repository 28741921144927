import React, { useEffect, useState } from 'react'
import Add from 'assets/images/add.png';
import Moment from "moment";
import LoaderMini from 'components/LoaderMini';

import CalendarIcon from 'assets/icons/icon_calendar.svg';
import ClockIcon from 'assets/icons/icon_clock.svg';
import { useParams } from 'react-router'

import LabelForm from 'components/LabelForm'
import ImageError from 'components/ImageError';
import Schedule from './schedule';
import { useGetAvailability, useCreateAvailability, useUpdateteAvailability, useDeleteAvailability } from 'features/UserManagement/hooks/userCalendar';
import CardDate from './cardDate';
import { onAlert } from 'components/Alert';

const FormUserCalendar: React.FC = () => {
  const params: any = useParams()

  const [response, setResponse] = useState(false);
  const [add, setAdd] = useState(false);
  const [objData, setObjData] = useState<any>({});
  const [listAvailability, setListAvailability] = useState([]);
  const { mutateAsync: getAvailability, isLoading: loadingGetAvailability } = useGetAvailability()
  const { mutateAsync: createAvailability, isLoading: loadingCreateAvailability } = useCreateAvailability()
  const { mutateAsync: updateteAvailability, isLoading: loadingUpdateteAvailability } = useUpdateteAvailability()
  const { mutateAsync: deleteAvailability, isLoading: loadingDeleteAvailability } = useDeleteAvailability()

  useEffect(() => {
    getListAvailability()
  }, []);

  const getListAvailability = async () => {
    setResponse(false)

    const listAvailability = await getAvailability(params.userId)
    if ((listAvailability?.data)) {
      setListAvailability(listAvailability?.data)
      setResponse(true)
    } else {

      if (listAvailability?.data) {
        onAlert.error(listAvailability?.data);
      } else {
        onAlert.error(listAvailability);
      }
    }
  }
  const postUpdatecreateAvailability = async (data: any) => {
    let monthStartRange = Moment(data.startDateRange).format('M');
    let yearStartRange = Moment(data.startDateRange).format('YYYY');
    let dayStartRange = Moment(data.startDateRange).format('DD');
    let dayEndDateRange = Moment(data.endDateRange).format('DD');
    let day = [];
    let dayMultiDate = [];
    let monthMultiple = '';
    let yearMultiple = '';
    for (let i = 0; i < parseInt(dayEndDateRange) + 1; i++) {
      if (i >= parseInt(dayStartRange)) {
        day.push(i)

      }
    }
    for (let i = 0; i < data.multiDate.length; i++) {
      const element = data.multiDate[i];
      let day = Moment(element).format('DD');
      monthMultiple = Moment(element).format('M');
      yearMultiple = Moment(element).format('YYYY');
      dayMultiDate.push(day)
    }
    const obj = {
      "update": data.update,
      "specialistsId": params.userId,
      "body": {
        "days": data.currentCalendarType === 1 ? day : dayMultiDate,
        "month": parseInt((data.currentCalendarType === 1 ? monthStartRange : monthMultiple)),
        "year": parseInt((data.currentCalendarType === 1 ? yearStartRange : yearMultiple)),
      },

    };

    setObjData(obj);
  }


  const deleteCardAvailability = async (data: any) => {
    const obj = {

      "specialistsId": params.userId,
      "body": {
        "month": data.month,
        "year": data.year
      },
    };

    const responseDeleteAvailability = await deleteAvailability(obj);

    if ((responseDeleteAvailability?.data)) {
      onAlert.success('Fecha eliminada');
      getListAvailability()
    } else {

      if (responseDeleteAvailability?.data) {
        onAlert.error(responseDeleteAvailability?.data);
      } else {
        onAlert.error(responseDeleteAvailability);
      }
    }

  }
  const onSubmit = async () => {

    let responseCreateAvailability

    if (objData?.update) {
      responseCreateAvailability = await updateteAvailability(objData)
    } else {
      responseCreateAvailability = await createAvailability(objData)

    }
    if ((responseCreateAvailability?.data)) {
      setAdd(false)
      onAlert.success('Fecha guardada');
      getListAvailability()
    } else {

      if (responseCreateAvailability?.data) {
        onAlert.error(responseCreateAvailability?.data);
      } else {
        onAlert.error(responseCreateAvailability);
      }
    }

  }


  return (
    <React.Fragment>

      <LabelForm icon={CalendarIcon} label="Disponibilidad" className='mb-4 font-medium	' />
      <LabelForm icon={ClockIcon} label="Horario" className='mt-6 mb-4 font-medium	' />
      <div className="flex w-full grid lg:grid-cols-2 2xl:grid-cols-3 gap-4 ">
        <div className=" bg-white rounded-xl border-solid border-[1px] border-grey-400  py-[16px] px-4 py-[24px]
    ">

          <LabelForm label="Horario" className='font-medium	 mb-[16px] ' />
          <Schedule title={'Hora'} description={'08:00 am - 06:00 pm'} icon={ClockIcon} onClickIcon={() => { }} />
        </div>
      </div>
      <LabelForm icon={CalendarIcon} label="Fecha" className='mt-6 mb-4 font-medium	' />

      <div className=" relative flex w-full grid lg:grid-cols-2 2xl:grid-cols-3 gap-4 pb-[400px]">
        {(loadingGetAvailability || loadingCreateAvailability || loadingUpdateteAvailability || loadingDeleteAvailability) &&
          <LoaderMini />
        }

        {add ?
          <div className="col relative ">

            <CardDate
              trashClick={() => setAdd(!add)}
              setData={(data: any) => { postUpdatecreateAvailability(data) }}
              onSubmit={onSubmit}
              label={'Seleccione una fecha'}
            />

          </div>
          :
          <div className='cursor-pointer' onClick={() => { setAdd(!add) }}>
            <ImageError
              className={'col text-text-body1 max-h-[208x] border-dashed border-[2px] rounded-[16px]	h-full  text-left text-caption p-[16px] '} classNameImage={'max-w-[40px] '} image={Add}>
              Agregar fecha
            </ImageError>
          </div>
        }
        {response &&
          listAvailability?.map((item, i) => (
            <div key={i} className="col relative">
              <CardDate
                trashClick={() => deleteCardAvailability(item)}
                initialData={item}
                setData={(data: any) => { postUpdatecreateAvailability({ ...data, update: true }) }}
                onSubmit={onSubmit}
                response={response}
                label={'Actualice una fecha'}
              />
            </div>
          ))
        }
      </div>
    </React.Fragment>
  )
}

export default FormUserCalendar
