import React, { useState, useEffect, useCallback } from 'react';
import { ShadowWrapper } from 'components/ShadowWrapper'
import Button from 'components/Button';
import IconAdd from 'assets/icons/icon_add.svg';
import IconSearch from 'assets/icons/icon_search.svg';
import Input from 'components/input';
import Loader from 'components/Loader'
import Pagination from 'components/Pagination';
import Breadcrumbs from 'components/Breadcrumbs';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'routes/routing';
import TableClinic from 'features/UserManagement/components/TableClinic';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { useGetClinicSearchResults } from 'features/UserManagement/hooks/useClinic';
import { ClinicSearchModel } from 'features/UserManagement/types/clinic';
import { useCreateClinic, useUpdateClinic } from 'features/UserManagement/hooks/useClinic';

const pageSize = 10;
const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

const ClinicNetworks: React.FC = () => {
  const history = useHistory();
  const params = useParams<any>();
  const networkId = params?.clinicNetworkId

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listClinics, setListClinics] = useState<ClinicSearchModel>({
    currentPage: 1,
    data: [],
    total: 1,
    totalPages: 1
});

  const { mutateAsync: mutateAsyncListClinic, isLoading: isLoadingGetList } = useGetClinicSearchResults();
  const { mutateAsync: asyncCreateClinic, isLoading: isLoadingCreateClinic } = useCreateClinic()
  const { mutateAsync: asyncUpdateClinic, isLoading: isLoadingUpdateClinic } = useUpdateClinic()

  const handleClickAdd = () => {
    if (
      listClinics && listClinics.data && (listClinics.data.length === 0 ||
      (listClinics.data.length > 0 &&
        listClinics.data[0].name !== ''))
    ) {
      setListClinics({
        ...listClinics,
        data: [
          {
              "name": "",
              "status": 1,
              "clinicNetworkName": "",
              "id": 0,
          },
          ...listClinics.data
        ]
      });
    }
  }

  const handleCancel = (index: number) => {
    const element = listClinics.data[index]
    if (!element.name) {
      const newList = listClinics.data.filter((el, i) => i !== index)
      setListClinics({
        ...listClinics,
        data: [
          ...newList
        ]
      });
    }
  }

  
  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  }

  const handleChangeSearch = (e: any) => {
    inputChange.next(e.target.value);
    setSearchValue(e.target.value);
  }

  const handleClickPrev = () => {
    history.push(`${routes.clinicNetwork.url}`)
  }

  const handleSave = async(sendData: any, action?: string) => {
    let response: any= null;
    if (action=== 'edit') {
      response = await asyncUpdateClinic(sendData)
    } else{
      response = await asyncCreateClinic(sendData)

    }
    if (response?.data) {
      onGetListClinic(searchValue,currentPage)
    }
  }

  const onGetListClinic = useCallback(async (searchValue?: string, currentPage?: number) => {
    const searchText = searchValue ? `&searchText=${searchValue}` : '';
    const query = `?page=${currentPage}${searchText}`;
    const response = await mutateAsyncListClinic({clinicNetworkId: networkId, query});
    setListClinics(response.data);
  }, [])

  const handleActivate = async (index: number, sendData?: any) => {
    const response = await asyncUpdateClinic({
      ...sendData,
      status: !sendData.status,
    })
    if (response?.data) {
      onGetListClinic(searchValue,currentPage)
    }
  }

  useEffect(() => {
    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value: any) => {
      setSearchText(value)
    })

    return () => {
      searchSubscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (searchValue === searchText) {
      if (currentPage !== 1) {
        setCurrentPage(1)

      } else {
        onGetListClinic(searchValue, 1);

      }
    }
  }, [searchValue, searchText, onGetListClinic]);

  useEffect(() => {
    onGetListClinic(searchValue, currentPage);
  }, [currentPage])

  return (
  <ShadowWrapper>
    <Breadcrumbs
      name={'Clínicas'}
      //prevPathName="Red de clínicas"
      currentPathName={'Lista de Clínicas'}
      onClickPrev={handleClickPrev}
      onClickHome={() => {history.push(`/user-management`)}}
      className='mb-6 px-4'
    />
    <div className='flex flex-col sm:flex-row gap-4 mb-4 justify-between px-4'>
      <Button
        type="submit"
        variant='primary'
        onClick={handleClickAdd}
        className=' flex items-center'>
          <img src={IconAdd} alt='agregar' />
          <span className='ml-2'>Agregar</span>
      </Button>
      <Input
        label="Buscar por clínica"
        value={searchValue}
        icon={IconSearch}
        onChange={handleChangeSearch}
        classNameWrapper='flex-[0_0_100%] max-w-[416px]'
        data-testid="searchByClinic"
      />
    </div>
    {
      isLoadingGetList ?
      <Loader />
      :
      <>
        <TableClinic
          data={listClinics?.data}
          handleClickListClinic={() => console.log('item')}
          handleActionEdit={() => console.log('edit')}
          handleSave={handleSave}
          handleActivate={handleActivate}
          handleCancel={handleCancel}
        />
        <div
          className='flex justify-end mt-[24px] px-4'
        >
          <Pagination
            currentPage={listClinics?.currentPage}
            totalCount={listClinics?.total}
            pageSize={pageSize}
            onPageChange={handleChangePage}
          />
        </div>
      </>
    }
  </ShadowWrapper>
)}

export default ClinicNetworks
