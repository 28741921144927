import React from 'react'
import IconClose from 'assets/icons/icon_close.svg'
import Button from 'components/Button'
import { Device } from '../services'

interface ReportDeviceProps {
  onCloseModal: () => any
  deviceSelected: Device,
  onSubmitForm: () => any
}

const StepContainer = ({
  stepNumber,
  stepInstructions
}: {
  stepNumber: string,
  stepInstructions: string
}) => {
  return (
    <div className='flex flex-row'>
      <div className='flex justify-center items-center'>
        <span className='font-medium w-10 h-10 text-base text-[#008EA3] flex justify-center items-center bg-[#DFF5FF] rounded-full mr-3'>{stepNumber}</span>
      </div>
      <div className='font-poppins text-sm font-normal leading-5 text-left text-[#141414] flex items-center w-full'>
        {stepInstructions}
      </div>
    </div>
  )
}

const instructions = [{
  stepNumber: "1",
  stepInstructions: "Díctale al Echo Dot: “Alexa, abre Home Hospital y vincula mi cuenta”"
}, {
  stepNumber: "2",
  stepInstructions: "Alexa te solicitará el código único"
}, {
  stepNumber: "3",
  stepInstructions: "Bríndale el código único que se encuentra en la parte superior o en tu bandeja de notificaciones"
}, {
  stepNumber: "4",
  stepInstructions: "¡Listo! Alexa te confirmará la vinculación del dispositivo con tu cuenta de Home Hospital"
}]


export const AlexaCodeGeneratedPairingInstructions: React.FC<ReportDeviceProps> = ({
  deviceSelected,
  onCloseModal,
  onSubmitForm
}: ReportDeviceProps) => {

  const MemoizedInstructionsSection = React.useMemo(() =>
    <div className='flex-1 mt-2 gap-[18px] flex flex-col justify-end border-b border-[#14141426] pb-6'>
      {
        instructions.map(element => <StepContainer key={element.stepNumber} stepInstructions={element.stepInstructions} stepNumber={element.stepNumber} />)
      }
    </div>, [instructions])

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative py-8 px-6 w-full max-w-[485px] ">
          <div className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden pt-6 pb-6 pl-4 pr-4">
            <div className="border-b-[1px] border-[#14141426] flex justify-between items-start mb-4 w-full pb-4">
              <div className="items-center w-full inline-block">
                <div className=''>
                  <span className="font-poppins text-base font-medium leading-5 tracking-wide text-left break-words text-[#141414]">
                    Pasos para vincular {deviceSelected?.deviceName}
                  </span>
                </div>
                <div className=''>
                  <span className="font-poppins text-xs font-normal leading-4 tracking-tight text-left text-[#141414]">
                    CÓDIGO ÚNICO: {deviceSelected?.planDevicePairing?.otp?.code}
                  </span>
                </div>
              </div>
              <button
                onClick={() => onCloseModal()}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6" />
              </button>
            </div>
            <div>
              {MemoizedInstructionsSection}
            </div>
            <div className="flex items-center justify-center w-full mt-6">
              <Button
                onClick={onSubmitForm}
                variant="primary"
                size="large"
                type="button"
                className='w-full font-poppins text-base font-semibold leading-6 tracking-wide text-center text-white'
              >
                Entendido
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}