import { routes } from "routes/routing";
import { AVAILABLE_ACTIONS } from "global/constants/enums";
import { replacePatientIdFromRoutes } from "./replacePatientId";
import { replacePatientAttentionId } from "./replacePatientAttentionId";
import { WORKPLAN_TABS_SECTIONS } from "features/PatientDashboard/PatientWorkPlan/types";

export function calculateActionRoutes({
    serviceId,
    patientId,
    currentLocation,
    action
}: {
    serviceId?: number,
    patientId: string
    currentLocation: any,
    action: AVAILABLE_ACTIONS
}) {
    if (action === AVAILABLE_ACTIONS.PATIENT_DETAILS_WORKPLAN) {
        return `${replacePatientIdFromRoutes(routes.patientWorkplan.url, patientId)}`;
    }
    if (action === AVAILABLE_ACTIONS.PATIENT_DETAILS_NOTES) {
        return `${replacePatientIdFromRoutes(routes.patientDailyEvolution.url, patientId)}`;
    }
    if (action === AVAILABLE_ACTIONS.EDIT_ATTENTION) {
        return replacePatientIdFromRoutes(routes.attentionsActionEditPatient.url, patientId);
    }
    if (action === AVAILABLE_ACTIONS.EDIT_PATIENT) {
        if (currentLocation.pathname.includes(routes.patients.url)) {
            return replacePatientIdFromRoutes(`${routes.patients.url}${routes.patient.url}/:documentPatient`, patientId);
        }
        if (currentLocation.pathname.includes(routes.clinicHistory.url)) {
            return replacePatientIdFromRoutes(routes.clinicHistoryPatientV2.url, patientId);
        }
    }
    if (action === AVAILABLE_ACTIONS.ATTENTIONS_HISTORY_DIRECT) {
        return replacePatientAttentionId(replacePatientIdFromRoutes(routes.patientHistoryElement.url, patientId), `${serviceId}`)
    }
    if (action === AVAILABLE_ACTIONS.ATTENTIONS_HISTORY) {
        if (currentLocation.pathname.includes(routes.patients.url)) {
            return replacePatientIdFromRoutes(`${routes.patients.url}${routes.patientHistory.url}/:documentPatient`, patientId);
        }
        if (currentLocation.pathname.includes(routes.clinicHistory.url)) {
            return replacePatientIdFromRoutes(routes.clinicHistorySpecificAttentionsPatient.url, patientId)
        }
        if (currentLocation.pathname === routes.attentions.url) {
            return replacePatientIdFromRoutes(routes.attentionsActionPatientAttentionsHistory.url, patientId)
        }
    }
    if (action === AVAILABLE_ACTIONS.NEW_ATTENTION) {
        return replacePatientIdFromRoutes(routes.clinicHistoryPatientAddAttention.url, patientId)
    }
    if (action === AVAILABLE_ACTIONS.ATTENTION_DETAILS) {
        if (currentLocation.pathname.startsWith(replacePatientIdFromRoutes(routes.patientHistoryElement.url, patientId))) {
            return replacePatientIdFromRoutes(replacePatientAttentionId(routes.patientHistoryElementAttentionDetails.url, `${serviceId}`), patientId);
        }
        if (serviceId && currentLocation.pathname.startsWith(replacePatientIdFromRoutes(routes.attentionsActionPatientAttentionsHistory.url, patientId))) {
            return replacePatientAttentionId(replacePatientIdFromRoutes(routes.attentionsActionPatientAttentionsHistorySpecificAttention.url, patientId), `${serviceId}`)
        }
        if (serviceId && currentLocation.pathname.startsWith(
            replacePatientIdFromRoutes(routes.clinicHistorySpecificAttentionsPatient.url, patientId)
        )
        ) {
            return replacePatientAttentionId(
                replacePatientIdFromRoutes(routes.clinicHistorySpecificAttentionForPatient.url, patientId)
                , `${serviceId}`
            );
        }
    }
    if (action === AVAILABLE_ACTIONS.ACTIVE_PATIENTS) {
        return routes.patients.url;
    }
    if (action === AVAILABLE_ACTIONS.WORKPLAN) {
        return routes.patientDashboard.url;
    }
    //TODO: Refactor this on their corresponding routes.
    if (action === AVAILABLE_ACTIONS.WORKPLAN_TREAMENT) {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('tab', WORKPLAN_TABS_SECTIONS.TREATMENT);
        return `${routes.patientDashboard.url}?${urlSearchParams.toString()}`;
    }
    if (action === AVAILABLE_ACTIONS.WORKPLAN_MEDICAL_PERSONEL) {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('tab', WORKPLAN_TABS_SECTIONS.MEDICAL_PERSONNEL);
        return `${routes.patientDashboard.url}?${urlSearchParams.toString()}`;
    }
    if (action === AVAILABLE_ACTIONS.WORKPLAN_DEVICES) {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('tab', WORKPLAN_TABS_SECTIONS.DEVICES);
        return `${routes.patientDashboard.url}?${urlSearchParams.toString()}`;
    }
    if (action === AVAILABLE_ACTIONS.WORKPLAN_RECOMMENDATIONS) {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('tab', WORKPLAN_TABS_SECTIONS.RECOMMENDATIONS);
        return `${routes.patientDashboard.url}?${urlSearchParams.toString()}`;
    }
    if (action === AVAILABLE_ACTIONS.WORKPLAN_ANNOTATIONS) {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('tab', WORKPLAN_TABS_SECTIONS.ANNOTATIONS);
        return `${routes.patientDashboard.url}?${urlSearchParams.toString()}`;
    }
    return "/";
}