import { FC } from "react"
import { NotificationCardActionsProps, NotificationCardHeaderProps, ReadNotificationCardFooterProps, ReadNotificationCardHeaderProps } from "./types"
import { notificationVariants } from "./NotificationCard.const"
import { getFooterDate, getHeaderDate, getLastname, getName } from "../utils"
import { EventType } from "global/constants/eventType"
import Button from "components/Button"
import { NotificationType } from "features/Notifications/services"
import { useNotificationDashboardContext } from "features/Notifications/hooks/use-notifications-dashboard-context"
import { NotificationCardActionCall } from "./NotificationCardNew.snippets.action.call"
import { NotificationCardActionReport } from "./NotificationCardNew.snippets.action.report"
import { NotificationCardPatientActionReleased } from "./NotificationCardNew.snippets.action.release"
import { NotificationCardActionPrescription } from "./NotificationCardNew.snippets.action.prescription"
import { NotificationCardActionRecommendation } from "./NotificationCardNew.snippets.action.recommendation"
import { NotificationCardActionAnnotations } from "./NotificationCardNew.snippets.action.annotations"


export const NotificationCardActions: FC<NotificationCardActionsProps> = ({
  variant,
  onClickAction,
  notification
}) => {
  const selectedType = useNotificationDashboardContext().filterData?.notificationType

  const notificationType = notification?.data?.body?.eventType || notification?.data?.body?.type;
  if (variant === NotificationType.call) {
    return <NotificationCardActionCall notification={notification} selectedGroup={selectedType} />
  }
  if (variant === NotificationType.report) {
    return <NotificationCardActionReport notification={notification} selectedGroup={selectedType} />
  }
  if (variant === NotificationType.prescription) {
    //Only aplicable for the patient.
    return <NotificationCardActionPrescription notification={notification} selectedGroup={selectedType} />
  }
  if (variant === NotificationType.recommendation) {
    //Only aplicable for the patient.
    return <NotificationCardActionRecommendation notification={notification} selectedGroup={selectedType} />
  }
  if (variant === NotificationType.annotations || [
    EventType.NOTIFICATION_TYPE_NOTE_DR_DAILY_EVOL,
    EventType.NOTIFICATION_TYPE_NOTE_PATIENT,
  ].includes(notificationType || "")) {
    return <NotificationCardActionAnnotations notification={notification} selectedGroup={selectedType} />
  }

  if (notificationType === EventType.NOTIFICATION_TYPE_PATIENT_RELEASED) {
    return <NotificationCardPatientActionReleased notification={notification} selectedGroup={selectedType} />
  }

  const selectedVariant = notificationVariants[variant]
  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${selectedVariant?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
          onClick={onClickAction}
        >
          <span className="flex flex-row items-center gap-2 pr-4">
            <img
              src={selectedVariant?.iconName}
              alt={selectedVariant?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              {selectedVariant?.labels.action}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}


export const ReadNotificationCardHeader: FC<
  ReadNotificationCardHeaderProps
> = ({ notification, onClickAction, alert }) => {
  const name = getName(notification.name)
  const lastname = getLastname(notification.name)
  const selectedVariant = notificationVariants[notification.notificationType]
  const date = notification.createdAt
    ? getHeaderDate(notification.createdAt)
    : null

  return (
    <div className={'flex flex-row justify-between items-center w-[100%]'}>
      {alert ? (
        <>
          <span className={`flex ${selectedVariant?.color}`}>
            <img
              className="mr-[17px]"
              src={selectedVariant?.iconAlert}
              alt=""
              width={20}
              height={20}
            />
            {selectedVariant?.titleType}
          </span>
          <span className={'text-caption text-[#ADADAD]'}>{date}</span>
        </>
      ) : (
        <div className={'flex flex-row items-center gap-2'}>
          <div
            className={
              'rounded-full bg-[#DFF5FF] text-[#008EA3] w-8 h-8 flex justify-center items-center font-semibold'
            }
          >
            {name.charAt(0)}
            {lastname.charAt(0)}
          </div>
          <span className={'text-body2'}>
            {name} {lastname}
          </span>
        </div>
      )}
      {notification.notificationType === NotificationType.report ? (
        <div>
          <Button
            className={`p-2 w-[135px] h-[32px] rounded-lg ${selectedVariant?.bg}`}
            onClick={onClickAction}
          >
            <span className="flex flex-row items-center gap-2 pr-4">
              <img
                src={selectedVariant?.iconName}
                alt={selectedVariant?.iconName}
                className="sm:w-[12px] sm:h-[12px]"
              />
              <span className={'text-white text-caption'}>
                {selectedVariant?.labels.action}
              </span>
            </span>
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export const ReadNotificationCardFooter: FC<
  ReadNotificationCardFooterProps
> = ({ notification }) => {
  const getFooterContent = () => {
    const selectedVariant = notificationVariants[notification.notificationType]
    const date = getFooterDate(notification.readAt)
    return `${selectedVariant?.labels.readByPreffix} ${notification.attendedName} el ${date}`
  }
  return (
    <div className={'flex flex-row justify-between items-center w-[100%]'}>
      <span className={'text-caption text-[#ADADAD]'}>
        {getFooterContent()}
      </span>
    </div>
  )
}

export const NotificationCardHeader: FC<NotificationCardHeaderProps> = ({
  notification,
  alert,
}) => {
  const name = getName(notification.name)
  const lastname = getLastname(notification.name)
  const date = notification.createdAt
    ? getHeaderDate(notification.createdAt)
    : null
  const readAtDate = notification.readAt ? getHeaderDate(notification.readAt) : null
  const selectedVariant = notificationVariants[notification.notificationType];
  const isAlexa = notification.deviceSource === 'alexa';
  const eventType = notification.data?.body?.eventType || notification.data?.body?.type;
  const isUrgent = [EventType.NOTIFICATION_TYPE_EMERGENCY_CALL].includes(eventType as any);
  return (
    <div className={'flex flex-col justify-between items-left w-[100%]'}>
      {alert ? (
        <span className={`flex ${selectedVariant?.color}`}>
          <img
            className="mr-[17px]"
            src={selectedVariant?.iconAlert}
            alt=""
            width={20}
            height={20}
          />
          {selectedVariant?.titleType}
        </span>
      ) : (
        <div className={'flex flex-row items-center gap-2 pb-1'}>
          <span className={'text-body2'}>
            {name} {lastname}
          </span>
          <div className='flex-1 flex justify-end'>
            {
              isAlexa && <span className='text-xs p-[2px_12px] gap-[10px] rounded-lg opacity-1 bg-[#DFF5FF] text-[#008EA3]'>
                Alexa
              </span>
            }
            {
              isUrgent && <span className='text-xs p-[2px_12px] gap-[10px] rounded-lg opacity-1 bg-[#FFE8E8] text-[#E21010] ml-1'>
                URGENTE
              </span>
            }
          </div>
        </div>
      )}
      <span className={'text-caption text-[#585A5B]'}>{date || readAtDate || ""}</span>
    </div>
  )
}

export const NotificationCardContainer: FC = ({ children }) => {
  return (
    <div
      className={
        'box-border flex flex-col items-start p-4 gap-4 w-[337px] bg-[#FFFFFF] border border-[#E2E2E2] shadow-[0px_4px_16px_rgba(0,0,0,0.05)] rounded-2xl flex-none order-0 flex-grow-0 shadow-[0px_4px_16px_0px_#0000000D] max-w-full'
      }
    >
      {children}
    </div>
  )
}

export const NotificationCardDescription: FC = ({ children }) => {
  return <div className="text-body2 text-grey-600 flex-1">{children}</div>
}