import { NotificationCardActionsProps } from "./types"
import { calculatePotentialNotificationActionRoute } from "../utils"
import { variants } from "../NotificationsCounter/NotificationsCounterConst"
import { NotificationType, useMarkNotificationAsRead } from "features/Notifications/services"
import useUser from "features/shared/hooks/useUser"
import { useHistory, useLocation } from "react-router-dom"
import { useState } from "react"


export const NotificationCardActionAnnotations = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification'],
  selectedGroup?: NotificationType
}) => {
  const history = useHistory();
  const location = useLocation();
  const { markNotificationAsRead } = useMarkNotificationAsRead()
  const { userCurrent } = useUser();
  const [handlingClick, setHandlingClick] = useState(false);

  const handleClickAction = async () => {
    if(handlingClick) return;
    setHandlingClick(true);

    const finalRoute = calculatePotentialNotificationActionRoute({
      notification,
      location: location as any,
      currentUserRoleSlug: userCurrent?.roleSlug
    })
    if (finalRoute && finalRoute !== '/') {
      try {
        markNotificationAsRead(`${notification?.notificationId}`);
        history.push(finalRoute);
      } catch (e) {
        console.error({
          e
        });
      }
    } else {
      console.log("Intent not recognized...", {
        finalRoute
      });
    }
    
    setHandlingClick(false);
  }

  return (
    <div className='w-fit-content' onClick={handleClickAction}>
      <div>
        <div
          className={`p-3 ${variants?.annotations?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
        >
          <span className="flex flex-row items-center gap-2">
            <img
              src={variants?.annotations?.active?.iconName}
              alt={variants?.annotations?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Revisar
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}