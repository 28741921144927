import React, { useEffect, useState } from "react";
import constants from 'configs/constants'
import GoogleMapReact from 'google-map-react';
import IconClose from 'assets/icons/icon_close.svg';
import IconChevronLeft from 'assets/icons/icon_chevron_left.svg';
import InputSearch from '../InputSearch';
import Button from '../Button';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import OptionMapAddress from '../OptionMapAddress';
import { useForm } from 'react-hook-form';
import Input from 'components/input';
import IconMapMarker from 'assets/icons/icon_map_marker.png';
import { AddresSelectedModel } from './MapModel';

interface Props {
  showModal: boolean;
  isEdit: boolean;
  data: AddresSelectedModel;
  region: string;
  onCloseModal: () => any;
  onSubmitAddress: (value: AddresSelectedModel) => any;
};

const apiKey = constants.REACT_APP_GOOGLE ;


const ModalMap: React.FC<Props> = (props) => {
  const { watch, register, setValue } = useForm();
  const [mapLocation, setMapLocation] = useState(props.region === 'pe' ? { lat: -12.071933, lng: -77.042701 } : { lat: 4.6486259, lng: -74.2478951 });
  const defaultLocation = props.region === 'pe' ? { lat: -12.071933, lng: -77.042701 } : { lat: 4.6486259, lng: -74.2478951 };
  const [valueSearch, setValueSearch] = useState('');
  const [addressSelected, setAddressSelected] = useState<any>({ main: '', secondary: '' });
  const [stepLocation, setStepLocation] = useState<number>(1);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: apiKey,
    language: 'es',
  });

  useEffect(() => {
    setMapLocation(props.region === 'pe' ? { lat: -12.071933, lng: -77.042701 } : { lat: 4.6486259, lng: -74.2478951 });
  }, [props.region]);

  useEffect(() => {
    if (props.isEdit) {
      setMapLocation({
        lat: props.data.lat,
        lng: props.data.lng,
      });

      const formatted_address = props?.data?.name?.split(',')
      const main = formatted_address?.shift();
      const secondary = formatted_address?.join(', ');

      setAddressSelected({ main, secondary });
      setValue('number', props.data.number);
      setValue('reference', props.data.reference);

      setStepLocation(3);
    }
  }, [props.isEdit, props.data])

  const createMapOptions = (maps: GoogleMapReact.Maps) => {
    return {
      fullscreenControl: false,
    }
  }

  const onSelectAddress = (address: any) => {
    setAddressSelected({
      main: address.structured_formatting.main_text,
      secondary: address.structured_formatting.secondary_text
    });
    setStepLocation(2);

    placesService?.getDetails(
      { placeId: address.place_id },
      (placeDetails: any) => {
        setMapLocation({
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng(),
        });
      }
    );
  }

  const onSubmitAddress = () => {
    const data: AddresSelectedModel = {
      name: addressSelected?.main + ', ' + addressSelected?.secondary,
      reference: watch('reference'),
      lat: mapLocation.lat,
      lng: mapLocation.lng,
      number: watch('number'),
      ubigeoId: 0
    };

    props.onSubmitAddress(data);
  }

  const onClickMap = (value: GoogleMapReact.ClickEventValue) => {
    if (stepLocation === 3) {
      const geocoder = new value.event.view.google.maps.Geocoder();

      geocoder.geocode({ location: { lat: value.lat, lng: value.lng } }).then((res: any) => {
        setMapLocation({
          lat: res.results[0].geometry.location.lat(),
          lng: res.results[0].geometry.location.lng()
        });

        const formatted_address = res.results[0].formatted_address.split(',')
        const main = formatted_address.shift();
        const secondary = formatted_address.join(', ');

        setAddressSelected({ main, secondary });
      });
    }
  }

  if (props.showModal) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative py-8 px-6 w-full h-full">
            <div
              className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none overflow-hidden"
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: apiKey,
                  language: 'es',
                  region: props.region,
                }}
                defaultCenter={defaultLocation}
                defaultZoom={props.isEdit ? 14 : 10}
                center={mapLocation}
                options={createMapOptions}
                onClick={onClickMap}
              >
                <Marker
                  lat={mapLocation.lat}
                  lng={mapLocation.lng}
                />
              </GoogleMapReact>

              <div className='w-[407px] p-4 bg-white rounded-lg drop-shadow-xl absolute bottom-[24px] left-[50%] translate-x-[-50%]'>
                {
                  stepLocation === 1 && (
                    <div>
                      <p className='text-body1 mb-4'>¿Donde vives?</p>

                      <InputSearch
                        name="search"
                        data-testid="modal_map_search_input"
                        label='Buscar dirección'
                        value={valueSearch}
                        onClearInput={() => { setValueSearch('') }}
                        onChange={(e: any) => {
                          getPlacePredictions({ input: e.target.value });
                          setValueSearch(e.target.value);
                        }}
                      />

                      <div className='overflow-y-auto max-h-[220px] mt-4'>
                        {valueSearch.length > 0 && placePredictions.map((item, index) => (
                          <OptionMapAddress
                            data-testid={`modal_map_option_address_${index}`}
                            key={index}
                            main={item.structured_formatting.main_text}
                            secondary={item.structured_formatting.secondary_text}
                            onClick={() => { onSelectAddress(item) }}
                          />
                        ))}
                      </div>
                    </div>
                  )
                }

                {
                  stepLocation === 2 && (
                    <div className='flex flex-col gap-4'>
                      <div
                        className='text-body1 flex items-center cursor-pointer'
                        onClick={() => setStepLocation(1)}
                      >
                        <img src={IconChevronLeft} className="mr-2" /> Información de la dirección
                      </div>

                      <OptionMapAddress
                        main={addressSelected?.main}
                        secondary={addressSelected?.secondary}
                        onClick={() => { }}
                      />

                      <div className='flex justify-center'>
                        <Button
                          className='!h-[24px] !text-caption'
                          size='small'
                          variant="primary"
                          onClick={() => { setStepLocation(3) }}
                          type="button"
                        >
                          Ajustar ubicación
                        </Button>
                      </div>

                      <div className='rounded-full bg-black opacity-10 h-[8px]'></div>

                      <Input
                        type="text"
                        typeValidation='text'
                        required
                        label="Dirección"
                        value={addressSelected?.main + ', ' + addressSelected?.secondary}
                        className="pointer-events-none "
                      />
                      <Input
                        data-testid="modal_map_number_input"
                        type="text"
                        typeValidation='number'
                        required
                        label="Piso / Apartamento / casa"
                        value={watch('number')}
                        {...register('number')}
                      />
                      <Input
                        data-testid="modal_map_reference_input"
                        required
                        label="Referencia"
                        value={watch('reference')}
                        {...register('reference')}
                      />
                    </div>
                  )
                }

                {
                  stepLocation === 3 && (
                    <div className='flex flex-col gap-4'>
                      <div
                        className='text-body1 flex items-center cursor-pointer'
                        onClick={() => setStepLocation(2)}
                      >
                        <img src={IconChevronLeft} className="mr-2" /> Ajustar ubicación
                      </div>

                      <Input
                        required
                        label="Dirección"
                        value={addressSelected?.main + ', ' + addressSelected?.secondary}
                        className="pointer-events-none "
                      />
                    </div>
                  )
                }

                {
                  stepLocation !== 3 ? (

                    <Button className='w-full mt-4'
                      variant="primary"
                      onClick={onSubmitAddress}
                      type="button"
                      disabled={!(watch('number') && watch('reference'))} >{(props?.data?.number || props?.data?.reference) ? 'Guardar' : 'AGREGAR DIRECCIÓN'} </Button>


                  ) : (
                    <Button
                      className='w-full mt-4'
                      variant="primary"
                      onClick={() => setStepLocation(2)}
                      type="button"
                    >
                      Aceptar
                    </Button>
                  )
                }

              </div>

              <button
                onClick={() => props.onCloseModal()}
                className="bg-white rounded-lg h-[44px] w-[44px] flex items-center justify-center absolute top-0 right-0 m-6 shadow-md"
              >
                <img src={IconClose} alt="close" />
              </button>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  } else {
    return <></>
  }
};

const Marker = (props: any) => {
  return (
    <img src={IconMapMarker} alt="marker" className='w-[32px] h-auto' />
  )
}

export default ModalMap;
