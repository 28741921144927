import { IGeoPoint, IGeoVisit, ILatLngElement, IMapData, IRouteData, IRouteRequestResponse, TMapsData } from "../types";
import { CAR_ICON, COMMON_POINT_ICON, ENDING_POINT_ICON, getInfoWindowContent, POLY_LINE_OPTIONS, STARTER_POINT_ICON, VISITED_ICON } from "./map.const";


export function haversine_distance(point1: ILatLngElement, point2: ILatLngElement) {
  const R = 6378137 / 1000; //Radius of the Earth in Kms
  const rlat1 = point1.lat() * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = point2.lat() * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (point2.lng() - point1.lng()) * (Math.PI / 180); // Radian difference (longitudes)
  const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
  return d;
}

export function getFurtherAwayVisit({
  originPoint,
  visits,
  maps
}: {
  originPoint: any,
  visits: IGeoVisit[],
  maps: any
}) {
  const points = visits;
  return points.map(element => {
    return {
      ...element,
      lengthFromStart: haversine_distance(originPoint, new maps.LatLng(element.lat, element.lng))
    }
  }).sort((element1, element2) => {
    if (element1.lengthFromStart > element2.lengthFromStart) return 1;
    if (element1.lengthFromStart < element2.lengthFromStart) return -1;
    return 0;
  }).pop()
}

export function getDirections({
  calculatedOrigin,
  waypoints,
  destination,
  maps
}: {
  waypoints: any[],
  calculatedOrigin: IGeoVisit,
  destination: IGeoVisit,
  maps: any
}): Promise<IRouteRequestResponse> {
  const directionsService = new maps.DirectionsService();
  return new Promise((accept, reject) => {
    directionsService.route(
      {
        origin: new maps.LatLng(calculatedOrigin.lat, calculatedOrigin.lng),
        destination: new maps.LatLng(destination.lat, destination.lng),
        waypoints,
        travelMode: maps.TravelMode.DRIVING,
        optimizeWaypoints: true
      } as any,
      (result: any, status: any) => {
        if (status === maps.DirectionsStatus.OK) {
          accept(result as IRouteRequestResponse);
        } else {
          console.error(`Error fetching directions: ${status}`, {
            waypoints
          });
          reject(status);
        }
      }
    );
  })
}


export async function loadVisitDirections({
  origin,
  visits,
  currentUserLocation,
  mapData
}: {
  origin: IGeoVisit,
  visits: IGeoVisit[],
  currentUserLocation?: IGeoVisit,
  mapData: TMapsData
}): Promise<{
  resultDirections: IRouteRequestResponse,
  resultAll: IRouteRequestResponse
} | undefined> {
  try {
    const points = visits;

    let originPoint = new mapData.maps.LatLng(origin.lat, origin.lng);
    if (currentUserLocation) {
      originPoint = new mapData.maps.LatLng(currentUserLocation.lat, currentUserLocation.lng);
    }

    const furthestAwayPoint = getFurtherAwayVisit({
      originPoint,
      visits: points,
      maps: mapData.maps
    })

    //We choose the further away point from the origin as our destination.
    const destination: IGeoVisit = furthestAwayPoint as any;

    let calculatedOrigin: any;

    calculatedOrigin = origin;
    if (currentUserLocation) {
      calculatedOrigin = currentUserLocation
    }

    const allWaypoints = points.map((point) => ({
      location: new mapData.maps.LatLng(point.lat, point.lng),
      stopover: true,
    }));

    const waypointsToTravel = points.filter(element => !element.visited).map((point) => ({
      location: new mapData.maps.LatLng(point.lat, point.lng),
      stopover: true,
    }));

    const [resultDirections, resultAll] = await Promise.all([
      getDirections({
        calculatedOrigin,
        waypoints: waypointsToTravel,
        destination,
        maps: mapData.maps
      }),
      getDirections({
        calculatedOrigin,
        waypoints: allWaypoints,
        destination,
        maps: mapData.maps
      })
    ])
    //We calculate all points as we'll need the proper sorting, wheter we'll visit them or not.
    return {
      resultDirections,
      resultAll
    } as any
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function initializeDirectionMarkers({
  origin,
  visits,
  mapData,
  directions,
  currentUserLocation
}: {
  origin: IGeoVisit,
  visits: IGeoVisit[],
  directions?: IRouteData,
  mapData?: IMapData
  currentUserLocation?: IGeoVisit,
}) {
  try {
    if (visits && visits.length && mapData && directions) {
      const directionsRendererInstance = new mapData.maps.DirectionsRenderer({
        polylineOptions: POLY_LINE_OPTIONS,
      });
      directionsRendererInstance.setDirections(directions.resultDirections);
      directionsRendererInstance.setMap(mapData.map);

      // Remove existing markers
      directionsRendererInstance.setOptions({
        suppressMarkers: true,
      });

      const sortedVisits: IGeoVisit[] = [...visits];
      //We sort the visits according to the calculated best possible order
      sortedVisits.sort((elementA, elementB) => {
        let indexForA = directions.resultAll.routes[0].waypoint_order.indexOf(visits.indexOf(elementA));
        let indexForB = directions.resultAll.routes[0].waypoint_order.indexOf(visits.indexOf(elementB));
        if (indexForA > indexForB) return 1;
        if (indexForA < indexForB) return -1;
        return 0;
      })

      // Create custom markers with SVG icons
      const newMarkers: any[] = [];
      const points = [origin, ...sortedVisits];

      points.forEach((point, index) => {
        let selectedIconSVG = "";
        let labelText = " ";
        let visited = point.visited;
        let showInfoWindow = false;
        if (point.lat === origin.lat && point.lng === origin.lng) {
          selectedIconSVG = STARTER_POINT_ICON;
        } else if (index === points.length - 1) {
          selectedIconSVG = COMMON_POINT_ICON;
          showInfoWindow = true;
          labelText = `${index}`;
        } else {
          if (visited) {
            selectedIconSVG = VISITED_ICON;
          } else {
            selectedIconSVG = COMMON_POINT_ICON;
          }
          labelText = `${index}`;
          showInfoWindow = true;
        }

        // Create an info window
        const infoWindow = new mapData.maps.InfoWindow({
          content: getInfoWindowContent(point),
        });

        const marker = new mapData.maps.Marker({
          position: { lat: point.lat, lng: point.lng },
          map: mapData.map,
          icon: {
            url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(selectedIconSVG),
            scaledSize: new mapData.maps.Size(22, 22), // Adjust the size as needed
          },
          label: {
            text: labelText,
            className: `marker-label ${visited ? "visited" : "non-visited"}`,
          },
        });

        if (showInfoWindow) {
          marker.addListener("click", () => {
            infoWindow.open({
              anchor: marker,
              map: mapData.map,
              shouldFocus: false,
            });
          });
        }

        newMarkers.push({
          marker,
          infoWindow: showInfoWindow ? infoWindow : null
        });
      });

      //This is a special marker for the current user location.
      if (currentUserLocation) {
        const marker = new mapData.maps.Marker({
          position: { lat: currentUserLocation.lat, lng: currentUserLocation.lng },
          map: mapData.map,
          icon: {
            url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(CAR_ICON),
            scaledSize: new mapData.maps.Size(22, 22), // Adjust the size as needed
          },
        });
        newMarkers.push({
          marker
        });
      }


      return { newMarkers, directionsRendererInstance };
    }
    return { newMarkers: [], directionsRendererInstance: undefined };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function getDefaultCenter({
  currentUserLocation,
  visits
}: {
  currentUserLocation?: IGeoVisit,
  visits?: IGeoVisit[]
}) {
  let defaultCenter: IGeoPoint = null as any;
  if (currentUserLocation) {
    defaultCenter = {
      lat: currentUserLocation.lat,
      lng: currentUserLocation.lng
    }
  } else {
    if (visits && visits[0]) {
      defaultCenter = {
        lat: visits[0]?.lat,
        lng: visits[0]?.lng
      }
    }
  }
  return defaultCenter;
}