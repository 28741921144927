import React from 'react'
import InputSelect from 'components/InputSelect'
import { Controller, Form, useForm } from 'react-hook-form'
import { StartingPointOptionsDefault } from './map.const';

interface Props {
  isRequired?: boolean
  classNameWrapper?: string
  onChangeTop: (value: any) => void
}

const SelectStartingPoint: React.FC<Props> = ({
  isRequired,
  classNameWrapper,
  onChangeTop
}) => {
  const { control } = useForm()

  return (
    <Controller
      control={control}
      name='startingPoint'
      render={
        ({ field: { onChange, value, name, ref } }) => (
          <InputSelect
            label={"Punto de Partida"}
            ref={ref}
            name={'startingPoint'}
            value={value}
            onSelect={(...event: any[]) => {
              onChange(event)
              onChangeTop(event[0])
            }}
            placeholder='Rol'
            required={isRequired}
            classNameWrapper={classNameWrapper}
          >
            {
              StartingPointOptionsDefault && StartingPointOptionsDefault.length > 0 && StartingPointOptionsDefault.map((speciality: any, i: any) => (
                <option key={i} value={speciality?.value}>{speciality?.label}</option>
              ))
            }
          </InputSelect>
        )
      }
    />
  )
}

export default SelectStartingPoint