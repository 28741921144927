import { useState, useEffect } from 'react'
import {
  LocalVideo,
  useLocalVideo,
  useRemoteVideoTileState,
  RemoteVideo,
  useFeaturedTileState,
  useSelectVideoQuality,
  useRosterState
} from "amazon-chime-sdk-component-library-react"
import IconVideo from 'assets/icons/icon_video_local.svg'
import IconVideoOff from 'assets/icons/icon_video_local_off.svg'
import IconUnmute from 'assets/icons/icon_unmute.svg'
import IconMute from 'assets/icons/icon_mute.svg'
import IconCall from 'assets/icons/icon_call.svg';

interface Props {
  videoTiles?: boolean
  handleAudio?: any
  mute?: boolean
  attendeeId?: string
  handleLeaveMeeting?: React.MouseEventHandler<HTMLButtonElement>
  alias?: string
}

const Chime: React.FC<Props> = ({
  videoTiles,
  handleAudio,
  mute,
  handleLeaveMeeting,
  alias
}) => {
  const { toggleVideo } = useLocalVideo()
  const { tileId } = useFeaturedTileState()
  const { tiles } = useRemoteVideoTileState()
  const selectVideoQuality = useSelectVideoQuality()
  const [isVideo, setIsVideo] = useState(false)
  const { roster } = useRosterState()

  const localVid = document.getElementById("localvideo")
  const remoteVid = document.getElementById(`remote_${tileId}`)
  if (localVid) {
    if (tileId === null) {
      localVid.classList.add("localvideo_main")
      localVid.classList.remove("localvideo_side")
    } else {
      localVid.classList.add("localvideo_side")
      localVid.classList.remove("localvideo_main")
    }
  }
  if (remoteVid) {
    if (tileId === null) {
      remoteVid.classList.add("remotevideo")
      remoteVid.classList.remove("ch-featured-tile")
    } else {
      remoteVid.classList.remove("remotevideo")
      remoteVid.classList.add("ch-featured-tile")
    }
  }

  const handleClickButtonVideo = () => {
    toggleVideo()
    setIsVideo(!isVideo)
    selectVideoQuality("360p")
  }

  const getAliasByUserJoined = (roster: any) => {
    const aux = Object.keys(roster)
    let config: any = roster[aux[1]]
    return `${config?.name?.charAt(0) || ''}${config?.lastName?.charAt(0) || ''}`
  }

  return (
    <>
      {videoTiles && (
        <>
          <div
            className="
              rounded-[16px] bg-[#001C2F] overflow-hidden w-[100%]
              h-[593px] xl:h-[394px]
              relative
            "
          >
            {
              Object.keys(roster).length === 1 &&
              <div className='bg-black/50 absolute text-white w-[100%] h-[100%] flex items-center justify-center'>
                <p className='font-medium text-2xl pb-2'>Llamando</p>
                <div className='animate-bounce h-[10px] w-[10px] rounded-full m-1 bg-white'></div>
                <div className='animate-bounce h-[10px] w-[10px] rounded-full m-1 bg-white'></div>
                <div className='animate-bounce h-[10px] w-[10px] rounded-full m-1 bg-white'></div>
              </div>
            }
            {
              tiles?.length > 0 ?
                tiles.map((item) =>
                  <RemoteVideo
                    tileId={item}
                    id={`remote_${item}`}
                    className="remotevideo"
                  />)
                :
                <div className="
                  flex items-center justify-center h-[100%]
                ">
                  <div className="
                    flex items-center justify-center
                    bg-[#DFF5FF] text-7xl w-[152px] h-[154px] rounded-full text-[#008EA3] uppercase
                  ">
                    {getAliasByUserJoined(roster)}
                  </div>
                </div>
            }
          </div>
          {
            isVideo &&
            <div className='
            w-[122px] h-[98px] right-[9px] bottom-[150px] absolute  z-1 rounded-lg overflow-hidden bg-[#1A4F73]
            xl:right-[-233px] xl:w-[134px] xl:h-[85px] xl:bottom-[62px]'>
              <div className="
                  flex items-center justify-center h-[100%] absolute w-[100%]
                ">
                <div className="
                  flex items-center justify-center 
                  bg-[#DFF5FF] text-3xl w-[66px] h-[65px] rounded-full text-[#008EA3] uppercase
                ">{alias}</div>
              </div>
              <LocalVideo id="localvideo" className='absolute top-0' />
            </div>
          }
          <div className='
            bottom-0 z-10 bg-[white] text-center w-[100%] pt-4 flex items-center justify-center'>
            <button onClick={handleAudio}><img src={mute ? IconUnmute : IconMute} alt='microfono' /></button>
            <button className='ml-[12px] bg-[red] w-[107px] text-white p-[8px] inline-flex justify-center rounded-md' onClick={handleLeaveMeeting}>
              <img className='mt-[4px]' src={IconCall} alt='finalizar llamada' />
              <span className='ml-[4px]'>Finalizar</span>
            </button>
            <button className='ml-[12px]' onClick={handleClickButtonVideo}><img src={isVideo ? IconVideo : IconVideoOff} alt='mostrar video loval' /></button>
          </div>
        </>
      )}
    </>
  )
}

export default Chime
